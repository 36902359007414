<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Создать новую Телеграм сессию</h2>
      <form v-if="!codeSent" @submit.prevent="validateAndSubmit">
        <div class="form-group">
          <label for="session_name">Имя сессии</label>
          <input
            type="text"
            v-model="session.session_name"
            id="session_name"
            required
            @input="validateSessionName"
          />
          <span v-if="errors.session_name" class="error">{{
            errors.session_name
          }}</span>
        </div>
        <div class="form-group">
          <label for="api_id">API ID</label>
          <input
            type="text"
            v-model="session.api_id"
            id="api_id"
            required
            @input="validateApiId"
          />
          <span v-if="errors.api_id" class="error">{{ errors.api_id }}</span>
        </div>
        <div class="form-group">
          <label for="api_hash">API Hash</label>
          <input
            type="text"
            v-model="session.api_hash"
            id="api_hash"
            required
            @input="validateApiHash"
          />
          <span v-if="errors.api_hash" class="error">{{
            errors.api_hash
          }}</span>
        </div>
        <div class="form-group">
          <label for="phone_number">Номер телефона</label>
          <input
            type="text"
            v-model="session.phone_number"
            id="phone_number"
            required
            @input="validatePhoneNumber"
          />
          <span v-if="errors.phone_number" class="error">{{
            errors.phone_number
          }}</span>
        </div>

        <button type="submit" class="create-button" :disabled="isSubmitting">
          Отправить код
        </button>
      </form>

      <!-- Code input form -->
      <div v-else>
        <h3>Введите код подтверждения</h3>
        <form @submit.prevent="validateAndCompleteAuth">
          <div class="form-group">
            <label for="code">Код из Telegram</label>
            <input
              type="text"
              v-model="code"
              id="code"
              required
              @input="validateCode"
            />
            <span v-if="errors.code" class="error">{{ errors.code }}</span>
          </div>
          <button type="submit" class="create-button" :disabled="isSubmitting">
            Завершить авторизацию
          </button>
        </form>
      </div>

      <button @click="closeModal" class="close-button">Закрыть</button>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/apiClient";
import forge from "node-forge";

export default {
  data() {
    return {
      session: {
        api_id: "",
        api_hash: "",
        phone_number: "",
        session_name: "",
      },
      errors: {
        api_id: "",
        api_hash: "",
        phone_number: "",
        session_name: "",
        code: "",
      },
      codeSent: false,
      code: "",
      isSubmitting: false,
      publicKey: null,
      sessionId: null, // Для хранения session_id, возвращаемого бэкендом
    };
  },
  methods: {
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async fetchPublicKey() {
      try {
        const response = await apiClient.get("/public-key");
        this.publicKey = forge.pki.publicKeyFromPem(response.data.public_key);
      } catch (error) {
        console.error("Ошибка при получении публичного ключа:", error);
        alert("Не удалось получить публичный ключ.");
      }
    },
    encryptData(data) {
      const aesKey = forge.random.getBytesSync(32);
      const iv = forge.random.getBytesSync(16);

      const cipher = forge.cipher.createCipher("AES-CBC", aesKey);
      cipher.start({ iv });
      cipher.update(forge.util.createBuffer(data));
      cipher.finish();
      const encryptedData = cipher.output.getBytes();

      const encryptedKey = this.publicKey.encrypt(aesKey, "RSA-OAEP", {
        md: forge.md.sha256.create(),
        mgf1: forge.md.sha256.create(),
      });

      return {
        encryptedData: forge.util.encode64(encryptedData),
        encryptedKey: forge.util.encode64(encryptedKey),
        iv: forge.util.encode64(iv),
      };
    },
    validateSessionName() {
      const nameRegex = /^\S+$/;
      if (!this.session.session_name) {
        this.errors.session_name = "Имя сессии не может быть пустым";
      } else if (!nameRegex.test(this.session.session_name)) {
        this.errors.session_name = "Имя сессии не должно содержать пробелы";
      } else {
        this.errors.session_name = "";
      }
    },
    validateApiId() {
      this.errors.api_id = /^[0-9]+$/.test(this.session.api_id)
        ? ""
        : "API ID должен содержать только цифры";
    },
    validateApiHash() {
      this.errors.api_hash =
        this.session.api_hash.length > 0 ? "" : "API Hash не может быть пустым";
    },
    validatePhoneNumber() {
      // Простая валидация; при необходимости улучшите ее
      this.errors.phone_number =
        this.session.phone_number.length > 0
          ? ""
          : "Номер телефона не может быть пустым";
    },
    validateCode() {
      this.errors.code = this.code.length > 0 ? "" : "Код не может быть пустым";
    },
    async validateAndSubmit() {
      this.validateSessionName();
      this.validateApiId();
      this.validateApiHash();
      this.validatePhoneNumber();

      if (Object.values(this.errors).every((error) => !error)) {
        await this.startAuth();
      }
    },
    async startAuth() {
      this.isSubmitting = true;
      try {
        const encryptedApiId = this.encryptData(this.session.api_id);
        const encryptedApiHash = this.encryptData(this.session.api_hash);
        const encryptedPhoneNumber = this.encryptData(
          this.session.phone_number
        );

        const encryptedSession = {
          api_id: encryptedApiId,
          api_hash: encryptedApiHash,
          phone_number: encryptedPhoneNumber,
          session_name: this.session.session_name,
        };

        const response = await apiClient.post("/start-auth", encryptedSession, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (response.data.status === "code_sent") {
          this.sessionId = response.data.session_id;
          this.codeSent = true;
          alert("Код отправлен на ваш номер телефона.");
        } else if (response.data.status === "already_authorized") {
          alert("Сессия уже авторизована.");
          this.$emit("created");
          this.$emit("close");
        }
      } catch (error) {
        console.error("Ошибка при отправке данных:", error);
        alert(
          "Произошла ошибка при отправке данных. Возможно, сессия с таким именем уже существует."
        );
      } finally {
        this.isSubmitting = false;
      }
    },
    async validateAndCompleteAuth() {
      this.validateCode();

      if (this.errors.code === "") {
        await this.completeAuth();
      }
    },
    async completeAuth() {
      this.isSubmitting = true;
      try {
        const encryptedCode = this.encryptData(this.code);

        const data = {
          session_id: this.sessionId,
          code: encryptedCode,
        };

        const response = await apiClient.post("/complete-auth", data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        if (response.data.status === "authorized") {
          alert("Авторизация успешно завершена.");
          this.$emit("created");
          this.$emit("close");
        } else {
          alert("Произошла ошибка: " + response.data.detail);
        }
      } catch (error) {
        console.error("Ошибка при завершении авторизации:", error);
        alert("Произошла ошибка при завершении авторизации.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку заднего экрана
    await this.fetchPublicKey();
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Включаем прокрутку заднего экрана при закрытии
  },
};
</script>

<style scoped>
/* Общие стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Затемненный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f7f9fc; /* Более светлый фон */
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Умеренная тень */
  transition: box-shadow 0.3s ease; /* Изменение только тени при наведении */
}

.modal-content:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Усиленная тень при наведении */
}

h2,
h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2c3e50; /* Темный синий цвет текста */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #34495e; /* Темный цвет текста для меток */
}

.form-group input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #3498db; /* Голубой цвет для фокуса */
  outline: none;
}

.error {
  color: #e74c3c;
  font-size: 0.85em;
  margin-top: 5px;
}

/* Стили для кнопок */
.create-button,
.close-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.create-button {
  background-color: #1abc9c; /* Зеленовато-голубой цвет для кнопки */
  color: white;
}

.create-button:hover:enabled {
  background-color: #16a085; /* Более темный оттенок при наведении */
}

.create-button[disabled] {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.close-button {
  background-color: #e74c3c; /* Красный цвет для кнопки закрытия */
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}

/* Адаптация для мобильных устройств */
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
