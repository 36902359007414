<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Создать новый Collect</h2>
      <form @submit.prevent="validateAndSubmit">
        <!-- Поле для названия Collect -->
        <div class="form-group">
          <label for="name">Название</label>
          <input
            type="text"
            v-model="collect.name"
            id="name"
            required
            @input="validateName"
          />
          <span v-if="errors.name" class="error">{{ errors.name }}</span>
        </div>

        <!-- Поле для описания Collect -->
        <div class="form-group">
          <label for="description">Описание</label>
          <input
            type="text"
            v-model="collect.description"
            id="description"
            required
            @input="validateDescription"
          />
          <span v-if="errors.description" class="error">{{
            errors.description
          }}</span>
        </div>

        <!-- Выпадающий список для выбора session_id -->
        <div class="form-group">
          <label for="session_id">Выберите сессию Telegram</label>
          <select
            v-model="collect.session_id"
            id="session_id"
            required
            @change="validateSessionId"
          >
            <option disabled value="">Пожалуйста, выберите сессию</option>
            <option
              v-for="session in availableSessions"
              :key="session.id"
              :value="session.id"
              :disabled="session.used"
            >
              {{ session.session_name }}
              <span v-if="session.used"> (Используется)</span>
            </option>
          </select>
          <span v-if="errors.session_id" class="error">{{
            errors.session_id
          }}</span>
        </div>

        <!-- Кнопка создания Collect -->
        <button type="submit" class="create-button" :disabled="isSubmitting">
          Создать
        </button>
      </form>

      <!-- Кнопка закрытия модального окна -->
      <button @click="closeModal" class="close-button">Закрыть</button>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/apiClient";

export default {
  data() {
    return {
      collect: {
        name: "",
        description: "",
        session_id: "", // Теперь выбираем session_id
      },
      errors: {
        name: "",
        description: "",
        session_id: "",
      },
      isSubmitting: false,
      availableSessions: [], // Список доступных сессий Telegram
    };
  },
  methods: {
    // Обработка клика по оверлею для закрытия модального окна
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },

    // Метод для закрытия модального окна
    closeModal() {
      this.$emit("close");
    },

    // Получение списка доступных сессий Telegram с бэкенда
    async fetchSessions() {
      try {
        const response = await apiClient.get("/sessions", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.availableSessions = response.data;
      } catch (error) {
        console.error("Ошибка при получении сессий:", error);
        alert("Не удалось получить список сессий.");
      }
    },

    // Валидация поля названия
    validateName() {
      const nameRegex = /^\S+$/;
      if (!this.collect.name) {
        this.errors.name = "Название не может быть пустым";
      } else if (!nameRegex.test(this.collect.name)) {
        this.errors.name = "Название не должно содержать пробелы";
      } else {
        this.errors.name = "";
      }
    },

    // Валидация поля описания
    validateDescription() {
      this.errors.description =
        this.collect.description.length > 0
          ? ""
          : "Описание не может быть пустым";
    },

    // Валидация выбранной сессии
    validateSessionId() {
      if (!this.collect.session_id) {
        this.errors.session_id = "Необходимо выбрать сессию Telegram";
      } else {
        this.errors.session_id = "";
      }
    },

    // Общий метод валидации перед отправкой формы
    async validateAndSubmit() {
      this.validateName();
      this.validateDescription();
      this.validateSessionId();

      // Проверяем, что нет ошибок валидации
      if (Object.values(this.errors).every((error) => !error)) {
        await this.createCollect();
      }
    },

    // Метод для отправки данных на бэкенд
    async createCollect() {
      this.isSubmitting = true;
      try {
        const collectPayload = {
          name: this.collect.name,
          description: this.collect.description,
          session_id: this.collect.session_id,
        };

        await apiClient.post("/collects", collectPayload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.$emit("created"); // Событие для уведомления родительского компонента о создании Collect
        this.$emit("close"); // Закрываем модальное окно
      } catch (error) {
        console.error("Ошибка создания collect:", error);
        alert("Произошла ошибка при создании collect.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку заднего экрана
    await this.fetchSessions(); // Получаем список сессий при монтировании компонента
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Включаем прокрутку заднего экрана при закрытии
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Затемненный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f7f9fc; /* Светлый фон */
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Легкая тень */
  transition: box-shadow 0.3s ease;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Усиленная тень при наведении */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2c3e50; /* Темно-синий цвет текста */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #34495e; /* Темный цвет текста для меток */
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus {
  border-color: #3498db; /* Цвет для фокуса */
  outline: none;
}

.error {
  color: #e74c3c;
  font-size: 0.85em;
  margin-top: 5px;
}

.create-button,
.close-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.create-button {
  background-color: #1abc9c; /* Зеленовато-голубой цвет */
  color: white;
}

.create-button:hover:enabled {
  background-color: #16a085; /* Более темный оттенок */
}

.create-button[disabled] {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.close-button {
  background-color: #e74c3c; /* Красный цвет */
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}

/* Адаптивные стили */
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
