<template>
  <div class="login-container">
    <h2>Авторизация</h2>
    <form @submit.prevent="login" class="login-form">
      <div class="form-group">
        <label for="username">Имя пользователя</label>
        <input
          type="text"
          v-model="username"
          id="username"
          placeholder="Введите имя пользователя"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input
          type="password"
          v-model="password"
          id="password"
          placeholder="Введите пароль"
          required
        />
      </div>
      <button type="submit" class="login-button">Войти</button>
    </form>
<!--     <div class="register-link">
      <p>
        Еще нет аккаунта?
        <router-link to="/register">Зарегистрироваться</router-link>
      </p>
    </div> -->
  </div>
</template>

<script>
// Импортируем apiClient и TokenService
import apiClient from "../services/apiClient";
import TokenService from "../services/token.service";
import forge from "node-forge";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    ...mapActions(["updateAuthentication"]),

    async getPublicKey() {
      try {
        const response = await apiClient.get("/public-key"); // Используем apiClient
        return response.data.public_key;
      } catch (error) {
        console.error("Ошибка получения публичного ключа:", error);
        alert("Не удалось получить публичный ключ.");
        throw error;
      }
    },

    async encryptData(publicKeyPem, data) {
      try {
        const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
        const encryptedData = publicKey.encrypt(
          forge.util.encodeUtf8(data),
          "RSA-OAEP",
          {
            md: forge.md.sha256.create(),
            mgf1: {
              md: forge.md.sha256.create(),
            },
          }
        );
        return forge.util.encode64(encryptedData);
      } catch (error) {
        console.error("Ошибка шифрования данных:", error);
        throw error;
      }
    },

    async login() {
      try {
        const publicKey = await this.getPublicKey();

        const encryptedUsername = await this.encryptData(
          publicKey,
          this.username
        );
        const encryptedPassword = await this.encryptData(
          publicKey,
          this.password
        );

        const formData = new FormData();
        formData.append("username", encryptedUsername);
        formData.append("password", encryptedPassword);

        // Запрос на получение access_token с использованием apiClient
        const response = await apiClient.post("/token", formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const accessToken = response.data.access_token;

        // Очищаем данные предыдущего пользователя
        TokenService.removeUser();

        // Сохраняем токен и данные пользователя через TokenService
        TokenService.updateLocalAccessToken(accessToken);
        TokenService.setUser({
          username: this.username,
          user_role: response.data.user_role,
        });

        this.updateAuthentication(true);
        this.$router.push("/dashboard");
      } catch (error) {
        if (error.response) {
          const errorMessage =
            error.response.data?.detail || "Ошибка авторизации";
          alert(errorMessage);
        } else {
          alert("Произошла ошибка при авторизации.");
        }
      }
    },
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #ffffff; /* Яркий белый фон */
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); /* Больше тени для улучшения визуального восприятия */
  transition: box-shadow 0.3s ease;
}

.login-container:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.15); /* Увеличение тени при наведении */
}

.login-container h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #333;
  font-size: 1.8rem;
  font-weight: bold;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px; /* Увеличен отступ для более удобного расположения элементов */
}

.form-group label {
  margin-bottom: 8px;
  display: block;
  color: #666;
  font-size: 1rem;
  font-weight: 600;
}

.form-group input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #f7f7f7; /* Легкий серый фон для инпутов */
  transition: border-color 0.3s, background-color 0.3s;
}

.form-group input:focus {
  border-color: #4caf50;
  background-color: #fff; /* Белый фон при фокусе */
  outline: none;
}

.login-button {
  width: 100%;
  padding: 12px;
  font-size: 1.1rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.login-button:hover {
  background-color: #45a049;
  transform: scale(1.02); /* Легкий эффект увеличения при наведении */
}

.register-link {
  margin-top: 25px;
  text-align: center;
  color: #666;
  font-size: 0.9rem;
}

.register-link a {
  color: #4caf50;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s, text-decoration 0.3s;
}

.register-link a:hover {
  color: #45a049;
  text-decoration: underline; /* Подчеркивание при наведении */
}

@media (max-width: 480px) {
  .login-container {
    margin: 20px auto;
    padding: 20px;
  }

  .login-button {
    padding: 14px;
    font-size: 1.2rem;
  }
}
</style>
