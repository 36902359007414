import axios from "axios";
import store from "../store";
import router from "../router";
import TokenService from "./token.service";

const apiClient = axios.create({
  baseURL: "/api",
  withCredentials: true, // Позволяет передавать куки с запросами
});

let isRefreshing = false;
let refreshSubscribers = [];

// Функция подписки на обновление токена
function subscribeTokenRefresh(callback) {
  refreshSubscribers.push(callback);
}

// Выполняет все запросы из очереди после обновления токена
function onRefreshed(token) {
  refreshSubscribers.forEach((callback) => callback(token));
  refreshSubscribers = [];
}

// Функция для обновления токена
async function refreshToken() {
  try {
    console.log("Пытаемся обновить токен...");

    const response = await apiClient.post("/refresh");
    const newAccessToken = response.data.access_token;

    if (newAccessToken) {
      TokenService.updateLocalAccessToken(newAccessToken);
    }

    store.dispatch("updateAuthentication", true);

    console.log("Токен успешно обновлен");

    onRefreshed(newAccessToken);

    return newAccessToken;
  } catch (error) {
    console.error("Не удалось обновить токен:", error);

    // Удаление данных и перенаправление на /login
    TokenService.removeUser();
    store.dispatch("updateAuthentication", false);
    try {
      await router.push("/login");
    } catch (err) {
      console.error("Ошибка перенаправления на /login:", err);
    }

    throw error;
  } finally {
    isRefreshing = false;
  }
}

// Перехватчик запросов
apiClient.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Перехватчик ответов
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    console.error(
      "Ошибка ответа:",
      error.response ? error.response.status : error.message
    );

    // Обработка ошибки 401 для refresh токена
    if (error.response && error.response.status === 401) {
      if (originalRequest.url === "/refresh") {
        console.error("Ошибка обновления токена. Перенаправляем на /login.");

        // Удаляем данные и перенаправляем пользователя
        TokenService.removeUser();
        store.dispatch("updateAuthentication", false);
        try {
          await router.push("/login");
        } catch (err) {
          console.error("Ошибка перенаправления на /login:", err);
        }
        return Promise.reject(error);
      }

      // Если это ошибка доступа и обновление токена не выполнялось
      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise((resolve, reject) => {
            subscribeTokenRefresh((token) => {
              if (token) {
                originalRequest.headers["Authorization"] = `Bearer ${token}`;
                resolve(apiClient(originalRequest));
              } else {
                reject(error);
              }
            });
          });
        }

        originalRequest._retry = true;
        isRefreshing = true;

        try {
          const newAccessToken = await refreshToken();
          originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return apiClient(originalRequest);
        } catch (refreshError) {
          console.error("Обновление токена не удалось:", refreshError);

          TokenService.removeUser();
          store.dispatch("updateAuthentication", false);
          try {
            await router.push("/login");
          } catch (err) {
            console.error("Ошибка перенаправления на /login:", err);
          }

          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
