<template>
  <div class="about">
    <h1>About Page</h1>
    <p>This is the about page of the application.</p>
  </div>
</template>

<script>
export default {
  name: "AboutView",
};
</script>

<style scoped>
.about {
  text-align: center;
  margin-top: 50px;
}
</style>
