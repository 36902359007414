<template>
  <div class="handler-card" :class="{ 'is-loading': loading }">
    <!-- Оверлей загрузки -->
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>

    <!-- Клик в этой области вызывает редактирование, только если не загружается -->
    <div class="clickable-area">
      <div class="card-header-with-actions">
        <h3>{{ sessionName }}</h3>
        <div v-if="isAdmin" class="session-actions-container">
          <div class="session-actions">
            <button
              @click.stop="refreshSession"
              class="action-button refresh"
              :class="{ disabled: isUsed }"
              :disabled="isUsed || loading"
              aria-label="Обновить Сессию"
            >
              <i class="fas fa-sync-alt"></i> Обновить
            </button>
          </div>
        </div>
      </div>

      <hr class="handler-divider" />

      <div class="card-body">
        <p class="status">
          Статус: <span :class="statusClass">{{ statusText }}</span>
        </p>
        <p v-if="isAdmin">
          Создана: <strong>{{ creatorName }}</strong>
        </p>
        <p v-if="isUsed">
          Использована: <strong>{{ used }}</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SessionCard",
  props: {
    sessionId: {
      type: Number,
      required: true,
    },
    sessionName: {
      type: String,
      required: true,
    },
    isAuthorized: {
      type: Boolean,
      required: true,
    },
    creatorName: {
      type: String,
      required: false,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
    used: {
      type: String,
      required: false,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusText() {
      return this.isAuthorized ? "Авторизована" : "Не авторизована";
    },
    statusClass() {
      return this.isAuthorized ? "status-authorized" : "status-not-authorized";
    },
    isUsed() {
      return this.used !== null && this.used !== "";
    },
  },
  methods: {
    refreshSession() {
      if (!this.isUsed && !this.loading) {
        this.$emit("refresh-session", this.sessionId);
      }
    },
  },
};
</script>

<style scoped>
.handler-card {
  position: relative; /* Для позиционирования оверлея загрузки */
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 30px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 25px;
  word-wrap: break-word;
}

.handler-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

.clickable-area {
  cursor: default; /* Убираем курсор "рука" */
  transition: box-shadow 0.3s ease;
  padding: 25px; /* Увеличен внутренний отступ */
}

.handler-card > .clickable-area {
  box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 15px; /* Закругление углов */
}

.handler-divider {
  margin: 20px 0;
  border: 1px solid #ddd;
}

.card-header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.card-header-with-actions h3 {
  font-size: 1.8rem;
  color: #2980b9;
  margin: 0;
  font-weight: 600;
}

.session-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.session-actions {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button.refresh {
  background-color: #1abc9c;
}

.action-button.refresh:hover {
  background-color: #16a085;
  transform: translateY(-2px);
}

.action-button.disabled {
  background-color: #bdc3c7; /* Серый цвет для неактивной кнопки */
  cursor: not-allowed; /* Меняем курсор на неактивный */
}

.action-button:disabled:hover {
  background-color: #bdc3c7;
  transform: none; /* Отключаем эффект при наведении для заблокированных кнопок */
}

.action-button i {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.card-body {
  font-size: 1rem;
  color: #2c3e50;
}

.card-body p {
  margin: 5px 0;
}

.description {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
  font-size: 1rem;
  color: #333;
}

.status {
  font-weight: 500;
}

.status-authorized {
  color: #27ae60;
}

.status-not-authorized {
  color: #c0392b;
}

/* Оверлей загрузки */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  z-index: 10; /* Поверх содержимого карты */
}

/* Спиннер */
.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Стиль для неактивных кнопок */
.action-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .card-header-with-actions h3 {
    font-size: 1.6rem;
  }

  .action-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .card-body {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .card-header-with-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-button {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }
}
</style>
