<template>
  <div class="handler-card" :class="{ 'is-loading': loading }">
    <!-- Оверлей загрузки -->
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>

    <!-- Клик в этой области вызывает редактирование, только если не загружается -->
    <div class="clickable-area">
      <div class="card-header-with-actions">
        <h3>{{ name }}</h3>
        <div v-if="isAdmin" class="handler-actions-container">
          <div class="handler-actions">
            <button
              v-if="status === 'exited' || status === 'not found'"
              @click.stop="$emit('start')"
              class="action-button start"
              aria-label="Start"
              :disabled="loading"
            >
              <i class="fas fa-play"></i> Start
            </button>
            <button
              v-if="status === 'running'"
              @click.stop="$emit('stop')"
              class="action-button stop"
              aria-label="Stop"
              :disabled="loading"
            >
              <i class="fas fa-stop"></i> Stop
            </button>
            <button
              v-if="status !== 'not found'"
              @click.stop="$emit('delete')"
              class="action-button delete"
              aria-label="Delete"
              :disabled="loading"
            >
              <i class="fas fa-trash-alt"></i> Delete
            </button>
          </div>
        </div>
      </div>

      <hr class="handler-divider" />

      <div class="card-body">
        <p class="status">
          Status: <span :class="statusClass">{{ status }}</span>
        </p>
        <p>
          Uptime: <span>{{ uptime }}</span>
        </p>
        <p v-if="session_name">
          Session: <strong>{{ session_name }}</strong>
        </p>
        <p v-if="description">
          Description: <span>{{ description }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CollectCard",
  props: {
    name: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    uptime: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    session_name: {
      type: String,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusClass() {
      return {
        "status-ok": this.status === "running",
        "status-exited": this.status === "exited",
        "status-error": this.status === "error",
        "status-not-found": this.status === "not found",
      };
    },
  },
};
</script>

<style scoped>
.handler-card {
  position: relative; /* Для позиционирования оверлея загрузки */
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 30px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 25px;
  word-wrap: break-word;
}

.handler-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

.clickable-area {
  cursor: default; /* Убираем курсор "рука" */
  transition: box-shadow 0.3s ease;
  padding: 25px; /* Увеличен внутренний отступ */
}

.handler-card > .clickable-area {
  box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 15px; /* Закругление углов */
}

.handler-divider {
  margin: 20px 0;
  border: 1px solid #ddd;
}

.card-header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.card-header-with-actions h3 {
  font-size: 1.8rem;
  color: #2980b9;
  margin: 0;
  font-weight: 600;
}

.handler-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.handler-actions {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button.start {
  background-color: #28a745;
}

.action-button.start:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.action-button.stop {
  background-color: #ffc107;
}

.action-button.stop:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
}

.action-button.delete {
  background-color: #dc3545;
}

.action-button.delete:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.action-button i {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.card-body {
  font-size: 1rem;
  word-wrap: break-word;
  color: #2c3e50;
}

.card-body p {
  margin: 5px 0;
}

.status {
  font-weight: 500;
}

.status-ok {
  color: #28a745;
}

.status-exited {
  color: #6c757d;
}

.status-error {
  color: #dc3545;
}

.status-not-found {
  color: #6c757d;
}

/* Оверлей загрузки */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  z-index: 10; /* Поверх содержимого карты */
}

/* Спиннер */
.spinner {
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Стиль для неактивных кнопок */
.action-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .card-header-with-actions h3 {
    font-size: 1.6rem;
  }

  .action-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .card-body {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .card-header-with-actions {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-button {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }
}
</style>
