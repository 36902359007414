<template>
  <div class="edit-handler-modal" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Редактирование Handler: {{ handlerName }}</h2>

      <!-- Keyfilter (переключатель) -->
      <div class="form-group">
        <label for="keyfilter">Фильтрация(ключевые слова и регулярные выражения)</label>
        <label class="switch">
          <input
            type="checkbox"
            v-model="formData.keyfilter"
            @change="handleKeyfilterChange"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <!-- Keywords (поля отображаются только если keyfilter = true) -->
      <div v-if="formData.keyfilter">
        <div class="form-group">
          <label for="keywords">Гибкие ключевые слова(Keywords)</label>
          <textarea
            id="keywords"
            v-model="formData.keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите гибкие ключевые слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Гибкие ключевые слова могут быть частями слов. Например, "купить" найдет "приКУПИТЬ...", "заКУПИТЬ..." и т.д.
          </small>
        </div>

        <div class="form-group">
          <label for="exact_keywords">Точные ключевые слова(Exact Keywords)</label>
          <textarea
            id="exact_keywords"
            v-model="formData.exact_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите точные ключевые слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Точные ключевые слова должны точно совпадать с сообщением. Например, "привет мир" найдет только "привет мир".
          </small>
        </div>

        <div class="form-group">
          <label for="excluded_keywords">Гибкие слова-исключения(Excluded Keywords)</label>
          <textarea
            id="excluded_keywords"
            v-model="formData.excluded_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите гибкие исключенные слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Гибкие исключенные слова исключат сообщения, содержащие эти слова или их части. Аналогично с гибкими ключевыми словами
          </small>
        </div>

        <div class="form-group">
          <label for="exact_excluded_keywords">Точные слова-исключения(Exact Excluded Keywords)</label>
          <textarea
            id="exact_excluded_keywords"
            v-model="formData.exact_excluded_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите точные исключенные слова, каждое с новой строки"
          ></textarea>
          <small class="form-text text-muted">
            Точные исключенные слова исключат сообщения, содержащие эти фразы в точном совпадении. Аналогично с точными ключевыми словами
          </small>
        </div>

        <div class="form-group">
          <label for="multi_keywords">
            Мультиусловия(Multi Keywords)
          </label>
          <textarea
            id="multi_keywords"
            v-model="formData.multi_keywords"
            class="scrollable-textarea"
            rows="6"
            placeholder="Например: +ключевое слово++точная фраза-исключение--точное исключение"
          ></textarea>
          <small class="form-text text-muted">
            Используйте '+' для гибких ключевых слов, '++' для точных ключевых фраз, '-' для гибких исключений и '--' для точных исключений. Например: <code>+привет++добрый день-спам--точное исключение</code>
          </small>
        </div>

        <div class="form-group">
          <label for="regex_patterns">
            Регулярные выражения(regex_patterns)
          </label>
          <textarea
            id="regex_patterns"
            v-model="formData.regex_patterns"
            class="scrollable-textarea"
            rows="6"
            placeholder="Например: \bводительск[а-яё]*\s+удостоверен[а-яё]*\b::ISM"
          ></textarea>
          <small class="form-text text-muted">
            Введите регулярное выражение а затем через :: флаги к нему(опционально).
            <code>'A': re.ASCII,
            'I': re.IGNORECASE,
            'L': re.LOCALE,
            'M': re.MULTILINE,
            'S': re.DOTALL,
            'X': re.VERBOSE,
            'D': re.DEBUG</code>
          </small>
        </div>
      </div>

      <!-- GPT (переключатель) -->
      <div class="form-group" v-if="isAdmin">
        <label for="gpt">GPT</label>
        <label class="switch">
          <input
            type="checkbox"
            v-model="formData.gpt"
            @change="handleGptChange"
          />
          <span class="slider round"></span>
        </label>
      </div>

      <!-- GPT Version (поле ввода версии GPT) -->
      <div class="form-group" v-if="formData.gpt || isAdmin">
        <label for="gpt_version">GPT Version</label>
        <input
          type="text"
          id="gpt_version"
          v-model="formData.gpt_version"
          placeholder="Введите версию GPT"
        />
      </div>

      <!-- Current Prompt (поле отображается только если gpt = true) -->
      <div class="form-group" v-if="formData.gpt || isAdmin">
        <label for="current_prompt">Current Prompt</label>
        <input
          type="text"
          id="current_prompt"
          v-model="formData.current_prompt"
          placeholder="Введите текущий запрос"
        />
      </div>

      <!-- Group IDs -->
      <div class="form-group">
        <label for="group_ids">Group IDs</label>
        <textarea
          id="group_ids"
          v-model="formData.group_ids"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID групп, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Monitor Users -->
      <div class="form-group">
        <label for="monitor_users">Monitor Users</label>
        <textarea
          id="monitor_users"
          v-model="formData.monitor_users"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID пользователей, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Black List -->
      <div class="form-group">
        <label for="black_list">Black List</label>
        <textarea
          id="black_list"
          v-model="formData.black_list"
          class="scrollable-textarea"
          rows="6"
          placeholder="Введите ID пользователей для черного списка, каждое с новой строки"
        ></textarea>
      </div>

      <!-- Additional fields for Admin -->
      <div v-if="isAdmin">
        <div class="form-group">
          <label for="description">Description</label>
          <input
            type="text"
            id="description"
            v-model="formData.description"
            placeholder="Введите описание"
          />
        </div>

        <!-- Group ID fields for Admin -->
        <div class="form-group">
          <label for="group_id_gpt_no">Group ID No</label>
          <textarea
            id="group_id_gpt_no"
            v-model="formData.group_id_gpt_no"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп GPT No, каждое с новой строки"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="group_id_no_nickname">Group ID YES-</label>
          <textarea
            id="group_id_no_nickname"
            v-model="formData.group_id_no_nickname"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп без никнейма, каждое с новой строки"
          ></textarea>
        </div>

        <div class="form-group">
          <label for="group_id_with_nickname">Group ID YES+</label>
          <textarea
            id="group_id_with_nickname"
            v-model="formData.group_id_with_nickname"
            class="scrollable-textarea"
            rows="6"
            placeholder="Введите ID групп с никнеймом, каждое с новой строки"
          ></textarea>
        </div>
      </div>

      <div class="button-group">
        <button class="primary-button" @click="saveHandlerConfig">
          Сохранить
        </button>
        <button class="secondary-button" @click="closeModal">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from "@/services/apiClient";

export default {
  props: {
    handlerName: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      handlerConfig: {},
      formData: {
        current_prompt: "",
        keywords: "",
        exact_keywords: "",
        excluded_keywords: "",
        exact_excluded_keywords: "",
        multi_keywords: "",
        regex_patterns: "",
        group_ids: "",
        keyfilter: false,
        monitor_users: "",
        gpt: false,
        gpt_version: "gpt-4o-mini",
        description: "",
        group_id_gpt_no: "",
        group_id_no_nickname: "",
        group_id_with_nickname: "",
        black_list: "",
      },
    };
  },
  mounted() {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку заднего экрана
    this.loadHandlerConfig();
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Включаем прокрутку заднего экрана при закрытии
  },
  methods: {
    async loadHandlerConfig() {
      try {
        const response = await apiClient.get(
          `/mongo/handlers/${this.handlerName}`
        );
        this.handlerConfig = response.data;

        // Заполняем форму данными из конфигурации
        this.formData.current_prompt = this.handlerConfig.current_prompt || "";
        this.formData.keywords = (this.handlerConfig.keywords || []).join("\n");
        this.formData.exact_keywords = (this.handlerConfig.exact_keywords || []).join("\n");
        this.formData.excluded_keywords = (this.handlerConfig.excluded_keywords || []).join("\n");
        this.formData.exact_excluded_keywords = (this.handlerConfig.exact_excluded_keywords || []).join("\n");
        this.formData.multi_keywords = (this.handlerConfig.multi_keywords || []).join("\n");
        this.formData.regex_patterns = (this.handlerConfig.regex_patterns || []).join("\n");
        this.formData.group_ids = (this.handlerConfig.group_ids || []).join("\n");
        this.formData.keyfilter = this.handlerConfig.keyfilter || false;
        this.formData.monitor_users = (this.handlerConfig.monitor_users || []).join("\n");
        this.formData.gpt = this.handlerConfig.gpt || false;
        this.formData.gpt_version = this.handlerConfig.gpt_version || "gpt-4o-mini";
        this.formData.description = this.handlerConfig.description || "";

        // Преобразуем массивы чисел в строки для корректного отображения
        this.formData.group_id_gpt_no = (this.handlerConfig.group_id_gpt_no || []).join("\n");
        this.formData.group_id_no_nickname = (this.handlerConfig.group_id_no_nickname || []).join("\n");
        this.formData.group_id_with_nickname = (this.handlerConfig.group_id_with_nickname || []).join("\n");
        this.formData.black_list = (this.handlerConfig.black_list || []).join("\n");
      } catch (error) {
        console.error("Ошибка при загрузке конфигурации хэндлера:", error);
      }
    },
    async saveHandlerConfig() {
      try {
        const updateData = {
          current_prompt: this.formData.current_prompt,
          keywords: this.formData.keywords.split("\n").filter(Boolean),
          exact_keywords: this.formData.exact_keywords
            .split("\n")
            .filter(Boolean),
          excluded_keywords: this.formData.excluded_keywords
            .split("\n")
            .filter(Boolean),
          exact_excluded_keywords: this.formData.exact_excluded_keywords
            .split("\n")
            .filter(Boolean),
          multi_keywords: this.formData.multi_keywords
            .split("\n")
            .filter(Boolean),
          regex_patterns: this.formData.regex_patterns
            .split("\n")
            .filter(Boolean),
          group_ids: this.formData.group_ids
            .split("\n")
            .filter(Boolean)
            .map(Number),
          keyfilter: this.formData.keyfilter,
          monitor_users: this.formData.monitor_users
            .split("\n")
            .filter(Boolean),
          gpt: this.formData.gpt,
          gpt_version: this.formData.gpt_version,
          description: this.formData.description,
          group_id_gpt_no: this.formData.group_id_gpt_no
            .split("\n")
            .filter(Boolean)
            .map(Number),
          group_id_no_nickname: this.formData.group_id_no_nickname
            .split("\n")
            .filter(Boolean)
            .map(Number),
          group_id_with_nickname: this.formData.group_id_with_nickname
            .split("\n")
            .filter(Boolean)
            .map(Number),
          black_list: this.formData.black_list.split("\n").filter(Boolean),
        };

        console.log("Update Data:", updateData); // Логирование для проверки данных

        await apiClient.put(`/mongo/handlers/${this.handlerName}`, updateData);
        this.closeModal();
      } catch (error) {
        console.error("Ошибка при сохранении конфигурации хэндлера:", error);
      }
    },
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.edit-handler-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow: hidden; /* Отключаем прокрутку заднего экрана */
}

.modal-content {
  background: #fff;
  padding: 30px; /* Увеличенное расстояние внутри */
  border-radius: 12px;
  max-width: 800px;
  width: 100%;
  max-height: 90vh; /* Ограничиваем высоту модального окна */
  overflow-y: auto; /* Включаем прокрутку, если контент превышает высоту окна */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Глубокая тень для объема */
  animation: fadeIn 0.3s ease-in-out; /* Анимация появления */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.form-group small {
  display: block;
  margin-top: 5px;
  color: #6c757d;
  font-size: 0.875em;
}

.form-group code {
  background-color: #f8f8f8;
  padding: 2px 4px;
  font-size: 0.875em;
  border-radius: 4px;
  color: #e83e8c;
}

.scrollable-textarea {
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  resize: none; /* Запрещаем изменение размера */
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-family: inherit;
  font-size: 1rem;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-family: inherit;
  font-size: 1rem;
  background-color: #f8f8f8;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #007bff;
  outline: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.primary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.primary-button:hover {
  background-color: #0056b3;
}

.secondary-button {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #6c757d;
  color: white;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.secondary-button:hover {
  background-color: #5a6268;
}
</style>
