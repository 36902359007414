import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem("access_token"),
    userRole: localStorage.getItem("user_role") || null, // Инициализируем роль из localStorage
  },
  mutations: {
    setAuthenticated(state, status) {
      state.isAuthenticated = status;
      if (!status) {
        state.userRole = null; // Сбрасываем роль при выходе
        localStorage.removeItem("user_role"); // Удаляем роль из localStorage
      }
    },
    setUserRole(state, role) {
      state.userRole = role;
      localStorage.setItem("user_role", role); // Сохраняем роль в localStorage
    },
  },
  actions: {
    updateAuthentication({ commit }, status) {
      commit("setAuthenticated", status);
    },
    updateUserRole({ commit }, role) {
      commit("setUserRole", role);
    },
  },
  getters: {
    isAuthenticated: (state) => state.isAuthenticated,
    isAdmin: (state) => state.userRole === "admin",
    userRole: (state) => state.userRole,
  },
});
