class TokenService {
  // Получить access токен из локального хранилища
  getLocalAccessToken() {
    return localStorage.getItem("access_token");
  }

  // Обновить access токен в локальном хранилище
  updateLocalAccessToken(token) {
    localStorage.setItem("access_token", token);
  }

  // Получить имя пользователя из локального хранилища
  getUsername() {
    return localStorage.getItem("username");
  }

  // Удалить токены и данные пользователя из локального хранилища
  removeUser() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("username");
    localStorage.removeItem("user_role");
    localStorage.clear(); // Очищаем все данные в localStorage, если это допустимо
  }

  // Сохранить данные пользователя в локальное хранилище
  setUser(user) {
    localStorage.setItem("username", user.username);
    localStorage.setItem("user_role", user.user_role);
  }
}

export default new TokenService();
