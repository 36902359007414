<template>
  <nav class="navbar">
    <div class="navbar-container">
      <router-link to="/" class="navbar-logo">
        <img src="@/assets/logo.png" alt="Логотип" class="logo-image" />
      </router-link>
      <ul class="navbar-menu" v-if="!isAuthPage">
        <li><router-link to="/">Главная</router-link></li>
        <li><router-link to="/about">О нас</router-link></li>
        <li v-if="isAuthenticated">
          <router-link to="/dashboard">Dashboard</router-link>
        </li>
        <li v-if="isAuthenticated" class="navbar-user">
          <span class="navbar-username">{{ currentUsername }}</span>
          <button @click="logout" class="logout-button">
            <i class="fas fa-sign-out-alt"></i> Выйти
          </button>
        </li>
        <li v-if="!isAuthenticated">
          <router-link to="/login">Войти</router-link>
        </li>
<!--         <li v-if="!isAuthenticated">
          <router-link to="/register">Регистрация</router-link>
        </li> -->
      </ul>
    </div>
  </nav>
</template>

<script>
import TokenService from "../services/token.service";
import { mapGetters, mapActions } from "vuex";
import apiClient from "../services/apiClient";

export default {
  data() {
    return {
      currentUsername: "", // Переменная для хранения актуального имени пользователя
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
    isAuthPage() {
      return this.$route.path === "/login" || this.$route.path === "/register";
    },
  },
  watch: {
    isAuthenticated(newVal) {
      if (newVal) {
        this.updateUsername(); // Обновляем имя пользователя при изменении статуса аутентификации
      }
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.updateUsername(); // Обновляем имя пользователя при монтировании, если пользователь уже аутентифицирован
    }
  },
  methods: {
    ...mapActions(["updateAuthentication"]),
    async updateUsername() {
      this.currentUsername = TokenService.getUsername(); // Получаем актуальное имя пользователя
    },
    async logout() {
      try {
        await apiClient.post("/logout");

        TokenService.removeUser();
        this.updateAuthentication(false);
        this.$router.push("/"); // Перенаправление на главную страницу
      } catch (error) {
        console.error("Ошибка при выходе из системы:", error);
        alert("Произошла ошибка при выходе из системы.");
      }
    },
  },
};
</script>

<style scoped>
.navbar {
  background-color: #2c3e50;
  color: white;
  padding: 15px 30px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.navbar:hover {
  background-color: #34495e;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar-logo {
  font-size: 1.8rem;
  color: white;
  text-decoration: none;
}

.logo-image {
  height: 45px;
  transition: transform 0.3s ease;
}

.logo-image:hover {
  transform: scale(1.1);
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 25px;
}

.navbar-menu li {
  display: flex;
  align-items: center;
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 500;
  transition: color 0.3s ease, transform 0.3s ease;
}

.navbar-menu a:hover {
  color: #1abc9c;
  transform: scale(1.05);
}

.navbar-user {
  display: flex;
  align-items: center;
}

.navbar-username {
  color: white;
  margin-right: 20px;
  font-weight: bold;
  font-size: 1.1rem;
}

.logout-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center; /* Выравнивание иконки и текста */
}

.logout-button i {
  margin-right: 8px; /* Отступ иконки от текста */
}

.logout-button:hover {
  background-color: #c0392b;
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .navbar-menu {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .navbar-user {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-logo {
    margin-bottom: 15px;
  }
}
</style>
