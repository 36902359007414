<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Создать нового Sender</h2>
      <form @submit.prevent="validateAndSubmit">
        <!-- Поле для названия Sender -->
        <div class="form-group">
          <label for="sender_name">Название Sender</label>
          <input
            type="text"
            v-model="sender.sender_name"
            id="sender_name"
            required
            @input="validateSenderName"
          />
          <span v-if="errors.sender_name" class="error">{{
            errors.sender_name
          }}</span>
        </div>

        <!-- Поле для описания Sender -->
        <div class="form-group">
          <label for="description">Описание</label>
          <input
            type="text"
            v-model="sender.description"
            id="description"
            required
            @input="validateDescription"
          />
          <span v-if="errors.description" class="error">{{
            errors.description
          }}</span>
        </div>

        <!-- Поле для OpenAI API Key -->
        <div class="form-group">
          <label for="openai_api_key">OpenAI API Key</label>
          <input
            type="text"
            v-model="sender.openai_api_key"
            id="openai_api_key"
            required
            @input="validateOpenaiApiKey"
          />
          <span v-if="errors.openai_api_key" class="error">{{
            errors.openai_api_key
          }}</span>
        </div>

        <!-- Поле для Assistant ID -->
        <div class="form-group">
          <label for="assistant_id">Assistant ID</label>
          <input
            type="text"
            v-model="sender.assistant_id"
            id="assistant_id"
            required
            @input="validateAssistantId"
          />
          <span v-if="errors.assistant_id" class="error">{{
            errors.assistant_id
          }}</span>
        </div>

        <!-- Поле для Telegram сообщения (Base64) -->
        <div class="form-group">
          <label for="telegram_message">Telegram Сообщение (Base64)</label>
          <textarea
            v-model="telegramMessage"
            id="telegram_message"
            required
            @input="
              validateTelegramMessage;
              formatNewLines;
            "
          ></textarea>
          <span v-if="errors.telegram_message_base64" class="error">{{
            errors.telegram_message_base64
          }}</span>
        </div>

        <!-- Выпадающий список для выбора session_id -->
        <div class="form-group">
          <label for="session_id">Выберите сессию Telegram</label>
          <select
            v-model="sender.session_id"
            id="session_id"
            required
            @change="validateSessionId"
          >
            <option disabled value="">Пожалуйста, выберите сессию</option>
            <option
              v-for="session in availableSessions"
              :key="session.id"
              :value="session.id"
              :disabled="session.used"
            >
              {{ session.session_name }}
              <span v-if="session.used"> (Используется)</span>
            </option>
          </select>
          <span v-if="errors.session_id" class="error">{{
            errors.session_id
          }}</span>
        </div>

        <!-- Кнопка создания Sender -->
        <button type="submit" class="create-button" :disabled="isSubmitting">
          Создать
        </button>
      </form>

      <!-- Кнопка закрытия модального окна -->
      <button @click="closeModal" class="close-button">Закрыть</button>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/apiClient";
import forge from "node-forge"; // Для шифрования

export default {
  props: {
    handler_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      sender: {
        sender_name: "",
        description: "",
        openai_api_key: "",
        assistant_id: "",
        session_id: "",
        telegram_message_base64: "",
        handler_id: this.handler_id,
      },
      telegramMessage: "",
      errors: {
        sender_name: "",
        description: "",
        openai_api_key: "",
        assistant_id: "",
        telegram_message_base64: "",
        session_id: "",
      },
      isSubmitting: false,
      availableSessions: [],
      publicKey: null, // Публичный ключ для шифрования
    };
  },
  watch: {
    handler_id(newVal) {
      this.sender.handler_id = newVal;
    },
  },
  // Функция для кодирования Unicode в Base64
  methods: {
    encodeBase64Unicode(str) {
      return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (_, p1) {
          return String.fromCharCode("0x" + p1);
        })
      );
    },

    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },

    closeModal() {
      this.$emit("close");
    },

    async fetchSessions() {
      try {
        const response = await apiClient.get("/sessions", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.availableSessions = response.data;
      } catch (error) {
        console.error("Ошибка при получении сессий:", error);
        alert("Не удалось получить список сессий.");
      }
    },

    // Получение публичного ключа для шифрования
    async fetchPublicKey() {
      try {
        const response = await apiClient.get("/public-key");
        this.publicKey = forge.pki.publicKeyFromPem(response.data.public_key);
      } catch (error) {
        console.error("Ошибка при получении публичного ключа:", error);
        alert("Не удалось получить публичный ключ.");
      }
    },

    encryptData(data) {
      const aesKey = forge.random.getBytesSync(32);
      const iv = forge.random.getBytesSync(16);

      const cipher = forge.cipher.createCipher("AES-CBC", aesKey);
      cipher.start({ iv });
      cipher.update(forge.util.createBuffer(data));
      cipher.finish();
      const encryptedData = cipher.output.getBytes();

      const encryptedKey = this.publicKey.encrypt(aesKey, "RSA-OAEP", {
        md: forge.md.sha256.create(),
        mgf1: forge.md.sha256.create(),
      });

      return {
        encryptedData: forge.util.encode64(encryptedData),
        encryptedKey: forge.util.encode64(encryptedKey),
        iv: forge.util.encode64(iv),
      };
    },

    validateSenderName() {
      const nameRegex = /^\S+$/;
      if (!this.sender.sender_name) {
        this.errors.sender_name = "Название Sender не может быть пустым";
      } else if (!nameRegex.test(this.sender.sender_name)) {
        this.errors.sender_name = "Название не должно содержать пробелы";
      } else {
        this.errors.sender_name = "";
      }
    },

    validateDescription() {
      this.errors.description =
        this.sender.description.length > 0
          ? ""
          : "Описание не может быть пустым";
    },

    validateOpenaiApiKey() {
      if (!this.sender.openai_api_key) {
        this.errors.openai_api_key = "OpenAI API Key не может быть пустым";
      } else {
        this.errors.openai_api_key = "";
      }
    },

    validateAssistantId() {
      if (!this.sender.assistant_id) {
        this.errors.assistant_id = "Assistant ID не может быть пустым";
      } else {
        this.errors.assistant_id = "";
      }
    },

    validateTelegramMessage() {
      if (!this.telegramMessage) {
        this.errors.telegram_message_base64 =
          "Telegram сообщение не может быть пустым";
      } else {
        try {
          // Используем кастомную функцию для кодирования Unicode в Base64
          this.encodeBase64Unicode(this.telegramMessage);
          this.errors.telegram_message_base64 = "";
        } catch (e) {
          this.errors.telegram_message_base64 = "Неверный формат Base64";
        }
      }
    },

    formatNewLines() {
      // Преобразуем переносы строк в символы '\n'
      this.telegramMessage = this.telegramMessage.replace(/\n/g, "\\n");
    },

    validateSessionId() {
      if (!this.sender.session_id) {
        this.errors.session_id = "Необходимо выбрать сессию Telegram";
      } else {
        this.errors.session_id = "";
      }
    },

    async validateAndSubmit() {
      this.validateSenderName();
      this.validateDescription();
      this.validateOpenaiApiKey();
      this.validateAssistantId();
      this.validateTelegramMessage();
      this.validateSessionId();

      if (Object.values(this.errors).every((error) => !error)) {
        await this.createSender();
      }
    },

    async createSender() {
      this.isSubmitting = true;
      try {
        const encodedTelegramMessage = this.encodeBase64Unicode(
          this.telegramMessage
        );

        // Шифрование ключа OpenAI API
        const encryptedOpenaiApiKey = this.encryptData(
          this.sender.openai_api_key
        );

        const senderPayload = {
          sender_name: this.sender.sender_name,
          handler_id: this.sender.handler_id,
          description: this.sender.description,
          openai_api_key: encryptedOpenaiApiKey,
          assistant_id: this.sender.assistant_id,
          session_id: this.sender.session_id,
          telegram_message_base64: encodedTelegramMessage,
        };

        console.log("Отправляемый payload:", senderPayload);

        await apiClient.post("/senders", senderPayload, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });

        this.$emit("created");
        this.closeModal();
      } catch (error) {
        console.error("Ошибка создания Sender:", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail
        ) {
          alert(`Ошибка: ${error.response.data.detail}`);
        } else {
          alert("Произошла ошибка при создании Sender.");
        }
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    document.body.style.overflow = "hidden";
    await this.fetchSessions();
    await this.fetchPublicKey(); // Получаем публичный ключ при монтировании
  },
  beforeUnmount() {
    document.body.style.overflow = "";
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Затемненный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f7f9fc; /* Светлый фон */
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); /* Легкая тень */
  transition: box-shadow 0.3s ease;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3); /* Усиленная тень при наведении */
}

#telegram_message {
  width: 100%;
  height: 200px; /* Увеличиваем высоту поля */
  resize: none; /* Отключаем возможность изменения размера */
  overflow-y: auto; /* Включаем вертикальную прокрутку */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2c3e50; /* Темно-синий цвет текста */
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #34495e; /* Темный цвет текста для меток */
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  border-color: #3498db; /* Цвет для фокуса */
  outline: none;
}

.error {
  color: #e74c3c;
  font-size: 0.85em;
  margin-top: 5px;
}

.create-button,
.close-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.create-button {
  background-color: #1abc9c; /* Зеленовато-голубой цвет */
  color: white;
}

.create-button:hover:enabled {
  background-color: #16a085; /* Более темный оттенок */
}

.create-button[disabled] {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.close-button {
  background-color: #e74c3c; /* Красный цвет */
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}

/* Адаптивные стили */
@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
