<template>
  <div class="sender-card" @click.stop>
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>
    <div class="card-header">
      <h3>{{ name }}</h3>
      <div v-if="isAdmin" class="sender-actions">
        <button
          v-if="status === 'exited' || status === 'not found'"
          @click.stop="$emit('start-sender', name)"
          class="action-button start"
          aria-label="Start"
          :disabled="loading"
        >
          <i class="fas fa-play"></i> Start
        </button>
        <button
          v-if="status === 'running'"
          @click.stop="$emit('stop-sender', name)"
          class="action-button stop"
          aria-label="Stop"
          :disabled="loading"
        >
          <i class="fas fa-stop"></i> Stop
        </button>
        <button
          v-if="status !== 'not found'"
          @click.stop="$emit('delete-sender', name)"
          class="action-button delete"
          aria-label="Delete"
          :disabled="loading"
        >
          <i class="fas fa-trash-alt"></i> Delete
        </button>
      </div>
    </div>
    <hr class="handler-divider" />

    <div class="card-body">
      <p class="status">
        Status: <span :class="statusClass">{{ status }}</span>
      </p>
      <p>
        Uptime: <span>{{ uptime }}</span>
      </p>
      <p v-if="description" class="description">
        Description: <span>{{ description }}</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: String, // Имя отправителя
    status: String, // Статус контейнера
    uptime: String, // Время работы контейнера
    description: String, // Описание
    isAdmin: Boolean, // Флаг администратора для отображения кнопок
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    statusClass() {
      return {
        "status-ok": this.status === "running",
        "status-exited": this.status === "exited",
        "status-error": this.status === "error",
        "status-not-found": this.status === "not found",
      };
    },
  },
};
</script>

<style scoped>
.sender-card {
  display: flex;
  flex-direction: column;
  width: 95%; /* Уменьшаем ширину для отделенности от HandlerCard */
  box-sizing: border-box;
  padding: 20px 25px; /* Уменьшаем отступы, чтобы карточка была меньше */
  border-radius: 10px; /* Закругленные углы для отделенности */
  background-color: #f9f9f9; /* Более светлый фон для отделенности */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Легкая тень */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px; /* Уменьшенный нижний отступ */
  position: relative;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.card-header h3 {
  font-size: 1.4rem;
  color: #2980b9;
  margin: 0;
  font-weight: 600;
}

.handler-divider {
  margin: 0;
  margin-bottom: 5px;
  border: 1px solid #ddd;
}

.sender-actions {
  display: flex;
  justify-content: center; /* Центрируем кнопки по горизонтали */
  align-items: center; /* Центрируем кнопки по вертикали */
  gap: 10px; /* Зазор между кнопками */
}

.action-button {
  padding: 8px 16px; /* Уменьшенные размеры кнопок */
  font-size: 0.9rem; /* Чуть меньший шрифт для кнопок */
}
.action-button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button.start {
  background-color: #28a745;
}

.action-button.start:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.action-button.stop {
  background-color: #ffc107;
}

.action-button.stop:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
}

.action-button.delete {
  background-color: #dc3545;
}

.action-button.delete:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.action-button i {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.card-body {
  font-size: 1rem;
  word-wrap: break-word;
  color: #2c3e50;
}

.card-body p {
  margin: 5px 0;
}

.description {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.status {
  font-weight: 500;
}

.status-ok {
  color: #28a745;
}

.status-exited {
  color: #6c757d;
}

.status-error {
  color: #dc3545;
}

.status-not-found {
  color: #6c757d;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px; /* Соответствует border-radius компонента */
  z-index: 10; /* Устанавливаем, чтобы оверлей был выше остального содержимого */
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Адаптивные стили */
@media (max-width: 768px) {
  .card-header h3 {
    font-size: 1.6rem;
  }

  .action-button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }

  .card-body {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .card-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-button {
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  }
}
</style>
