<template>
  <div class="modal-overlay" @click="handleOverlayClick">
    <div class="modal-content">
      <h2>Создать новый Handler</h2>
      <form @submit.prevent="validateAndSubmit">
        <!-- Название Handler -->
        <div class="form-group">
          <label for="handler_name">Название Handler</label>
          <input
            type="text"
            v-model="handler.handler_name"
            id="handler_name"
            required
            @input="validateHandlerName"
          />
          <span v-if="errors.handler_name" class="error">{{
            errors.handler_name
          }}</span>
        </div>

        <!-- Описание Handler -->
        <div class="form-group">
          <label for="description">Описание</label>
          <input
            type="text"
            v-model="handler.description"
            id="description"
            required
            @input="validateDescription"
          />
          <span v-if="errors.description" class="error">{{
            errors.description
          }}</span>
        </div>

        <!-- Bot Token -->
        <div class="form-group">
          <label for="bot_token">Bot Token</label>
          <input
            type="text"
            v-model="handler.bot_token"
            id="bot_token"
            required
            @input="validateBotToken"
          />
          <span v-if="errors.bot_token" class="error">{{
            errors.bot_token
          }}</span>
        </div>

        <!-- Sheet Name -->
        <div class="form-group">
          <label for="sheet_name">Sheet Name</label>
          <input
            type="text"
            v-model="handler.sheet_name"
            id="sheet_name"
            required
            @input="validateSheetName"
          />
          <span v-if="errors.sheet_name" class="error">{{
            errors.sheet_name
          }}</span>
        </div>

        <!-- Кнопка создания -->
        <button type="submit" class="create-button" :disabled="isSubmitting">
          Создать
        </button>
      </form>

      <!-- Кнопка закрытия -->
      <button @click="closeModal" class="close-button">Закрыть</button>
    </div>
  </div>
</template>

<script>
import apiClient from "../services/apiClient";
import forge from "node-forge";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      handler: {
        handler_name: "",
        description: "",
        bot_token: "",
        sheet_name: "",
      },
      errors: {},
      isSubmitting: false,
      publicKey: null,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
  },
  methods: {
    handleOverlayClick(event) {
      if (event.target === event.currentTarget) {
        if (confirm("Вы уверены, что хотите закрыть окно?")) {
          this.closeModal();
        }
      }
    },
    closeModal() {
      this.$emit("close");
    },
    async fetchPublicKey() {
      try {
        const response = await apiClient.get("/public-key");
        this.publicKey = forge.pki.publicKeyFromPem(response.data.public_key);
      } catch (error) {
        console.error("Ошибка при получении публичного ключа:", error);
        alert("Не удалось получить публичный ключ.");
      }
    },
    encryptData(data) {
      const aesKey = forge.random.getBytesSync(32);
      const iv = forge.random.getBytesSync(16);

      const cipher = forge.cipher.createCipher("AES-CBC", aesKey);
      cipher.start({ iv });
      cipher.update(forge.util.createBuffer(data));
      cipher.finish();
      const encryptedData = cipher.output.getBytes();

      const encryptedKey = this.publicKey.encrypt(aesKey, "RSA-OAEP", {
        md: forge.md.sha256.create(),
        mgf1: forge.md.sha256.create(),
      });

      return {
        encryptedData: forge.util.encode64(encryptedData),
        encryptedKey: forge.util.encode64(encryptedKey),
        iv: forge.util.encode64(iv),
      };
    },
    validateHandlerName() {
      const nameRegex = /^\S+$/;
      this.errors.handler_name =
        this.handler.handler_name.length > 0
          ? nameRegex.test(this.handler.handler_name)
            ? ""
            : "Название Handler не должно содержать пробелы"
          : "Название Handler не может быть пустым";
    },
    validateDescription() {
      this.errors.description =
        this.handler.description.length > 0
          ? ""
          : "Описание не может быть пустым";
    },
    validateBotToken() {
      this.errors.bot_token =
        this.handler.bot_token.length > 0
          ? ""
          : "Bot Token не может быть пустым";
    },
    validateSheetName() {
      this.errors.sheet_name =
        this.handler.sheet_name.length > 0
          ? ""
          : "Sheet Name не может быть пустым";
    },
    isValidUrl(url) {
      try {
        new URL(url);
        return true;
      } catch (_) {
        return false;
      }
    },
    async validateAndSubmit() {
      this.validateHandlerName();
      this.validateDescription();

      this.validateBotToken();
      this.validateSheetName();

      if (Object.values(this.errors).every((error) => !error)) {
        await this.createHandler();
      }
    },
    async createHandler() {
      this.isSubmitting = true;
      try {
        const encryptedBotToken = this.encryptData(this.handler.bot_token);

        const handlerData = {
          ...this.handler,
          bot_token: encryptedBotToken,
        };

        await apiClient.post("/handlers", handlerData);
        this.$emit("created");
        this.closeModal();
      } catch (error) {
        console.error("Ошибка создания handler:", error);
        alert("Произошла ошибка при создании handler.");
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  async mounted() {
    document.body.style.overflow = "hidden"; // Отключаем прокрутку заднего экрана
    await this.fetchPublicKey();
  },
  beforeUnmount() {
    document.body.style.overflow = ""; // Включаем прокрутку заднего экрана при закрытии
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f7f9fc; /* Светлый фон */
  padding: 30px;
  border-radius: 15px;
  width: 500px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.3);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #2c3e50;
  text-align: center;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #34495e;
}

.form-group input {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #bdc3c7;
  transition: border-color 0.3s ease;
}

.form-group input:focus {
  border-color: #3498db;
  outline: none;
}

.error {
  color: #e74c3c;
  font-size: 0.85em;
  margin-top: 5px;
}

.create-button,
.close-button {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 15px;
}

.create-button {
  background-color: #1abc9c;
  color: white;
}

.create-button:hover:enabled {
  background-color: #16a085;
}

.create-button[disabled] {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.close-button {
  background-color: #e74c3c;
  color: white;
}

.close-button:hover {
  background-color: #c0392b;
}

@media (max-width: 600px) {
  .modal-content {
    width: 90%;
    padding: 20px;
  }

  h2 {
    font-size: 1.5rem;
  }
}
</style>
