<template>
  <div class="dashboard">
    <h1>Добро пожаловать, {{ username }}</h1>

    <!-- Раздел Телеграм Сессии -->
    <div class="dashboard-section">
      <div class="section-header">
        <h2>Телеграм Сессии</h2>
        <button
          @click="toggleSessions"
          class="collapse-button"
          :aria-expanded="!isSessionsCollapsed"
          aria-controls="sessions-content"
          aria-label="Toggle Телеграм Сессии"
        >
          <i
            :class="
              isSessionsCollapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
            "
          ></i>
        </button>
      </div>
      <transition name="slide-fade">
        <div
          v-show="!isSessionsCollapsed"
          id="sessions-content"
          class="sessions-container"
        >
          <SessionCard
            v-for="session in sessions"
            :key="session.id"
            :session-id="session.id"
            :session-name="session.session_name"
            :isAuthorized="session.is_authorized"
            :creatorName="session.creator_username"
            :isAdmin="isAdmin"
            :used="session.used"
            @refresh-session="handleRefreshSession"
            :loading="loadingSessions[session.id] || false"
          />
          <div class="create-button-wrapper">
            <button @click="showCreateSessionModal" class="create-button">
              <i class="fas fa-plus"></i> Создать Сессию
            </button>
          </div>
        </div>
      </transition>
    </div>

    <!-- Раздел Сбор -->
    <div class="dashboard-section" v-if="isAdmin">
      <div class="section-header">
        <h2>Сбор</h2>
        <button
          @click="toggleCollects"
          class="collapse-button"
          :aria-expanded="!isCollectsCollapsed"
          aria-controls="collects-content"
          aria-label="Toggle Сбор"
        >
          <i
            :class="
              isCollectsCollapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
            "
          ></i>
        </button>
      </div>
      <transition name="slide-fade">
        <div
          v-show="!isCollectsCollapsed"
          id="collects-content"
          class="collects-container"
        >
          <CollectCard
            v-for="collect in collects"
            :key="collect.id"
            :name="collect.name"
            :description="collect.description"
            :status="collect.status"
            :uptime="collect.uptime"
            :session_name="collect.session_name"
            :isAdmin="isAdmin"
            :isHandler="false"
            :loading="loadingCollects[collect.name] || false"
            @start="startCollect(collect.name)"
            @stop="stopCollect(collect.name)"
            @delete="deleteCollect(collect.name)"
          />
          <div class="create-button-wrapper">
            <button @click="showCreateCollectModal" class="create-button">
              <i class="fas fa-plus"></i> Создать Collect
            </button>
          </div>
        </div>
      </transition>
    </div>

    <!-- Раздел Фильтрации -->
    <div class="dashboard-section">
      <div class="section-header">
        <h2>Фильтрации</h2>
        <button
          @click="toggleHandlers"
          class="collapse-button"
          :aria-expanded="!isHandlersCollapsed"
          aria-controls="handlers-content"
          aria-label="Toggle Фильтрации"
        >
          <i
            :class="
              isHandlersCollapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
            "
          ></i>
        </button>
      </div>
      <transition name="slide-fade">
        <div
          v-show="!isHandlersCollapsed"
          id="handlers-content"
          class="handlers-container"
        >
          <HandlerCard
            v-for="handler in handlers"
            :key="handler.id"
            :name="handler.name"
            :description="handler.description"
            :status="handler.status"
            :uptime="handler.uptime"
            :send="handler.send"
            :isAdmin="isAdmin"
            :isHandler="true"
            :handler_id="handler.id"
            :loading="loadingHandlers[handler.name] || false"
            :loadingsenders="loadingSenders"
            :senders="handler.senders"
            @create-sender="showCreateSenderModal"
            @start="startHandler(handler.name)"
            @stop="stopHandler(handler.name)"
            @delete="deleteHandler(handler.name)"
            @start-sender="startSender"
            @stop-sender="stopSender"
            @delete-sender="deleteSender"
            @open-edit-handler="openEditHandler"
            @toggle-send="handleToggleSend"
          />
          <div class="create-button-wrapper" v-if="isAdmin">
            <button @click="showCreateHandlerModal" class="create-button">
              <i class="fas fa-plus"></i> Создать Handler
            </button>
          </div>
        </div>
      </transition>
    </div>

    <!-- Модальные окна -->
    <CreateSessionModal
      v-if="isCreateSessionModalVisible"
      @close="isCreateSessionModalVisible = false"
      @created="fetchSessions"
    />
    <RefreshSessionModal
      v-if="isRefreshSessionModalVisible"
      :session-id="sessionToRefresh"
      @close="isRefreshSessionModalVisible = false"
      @refreshed="fetchSessions"
    />
    <CreateCollectModal
      v-if="isCreateCollectModalVisible"
      @close="isCreateCollectModalVisible = false"
      @created="fetchCollects"
    />
    <CreateHandlerModal
      v-if="isCreateHandlerModalVisible"
      @close="isCreateHandlerModalVisible = false"
      @created="fetchHandlers"
    />
    <EditHandler
      v-if="isEditHandlerModalVisible"
      :handler-name="currentHandlerName"
      :isAdmin="isAdmin"
      @close="closeEditHandlerModal"
    />
    <CreateSenderModal
      v-if="isCreateSenderModalVisible"
      :handler_id="selectedHandlerId"
      @close="
        isCreateSenderModalVisible = false;
        selectedHandlerId = null;
      "
      @created="fetchHandlers"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import apiClient from "../services/apiClient";
import SessionCard from "./SessionCard.vue";
import CreateSessionModal from "./CreateSessionModal.vue";
import RefreshSessionModal from "./RefreshSessionModal.vue";
import CollectCard from "./CollectCard.vue";
import HandlerCard from "./HandlerCard.vue";
import CreateCollectModal from "./CreateCollectModal.vue";
import CreateHandlerModal from "./CreateHandlerModal.vue";
import EditHandler from "./EditHandler.vue";
import CreateSenderModal from "./CreateSenderModal.vue";

export default {
  components: {
    SessionCard,
    CreateSessionModal,
    RefreshSessionModal,
    CollectCard,
    HandlerCard,
    CreateCollectModal,
    CreateHandlerModal,
    EditHandler,
    CreateSenderModal,
  },
  data() {
    return {
      sessions: [],
      collects: [],
      handlers: [],
      senders: [],
      isCreateSessionModalVisible: false,
      isRefreshSessionModalVisible: false,
      sessionToRefresh: null,
      isCreateCollectModalVisible: false,
      isCreateHandlerModalVisible: false,
      isEditHandlerModalVisible: false,
      isCreateSenderModalVisible: false,
      currentHandlerName: "",
      selectedHandlerId: null,
      statusInterval: null,

      // Свойства для сворачивания разделов
      isSessionsCollapsed: false,
      isCollectsCollapsed: false,
      isHandlersCollapsed: false,

      // Состояния загрузки
      loadingHandlers: {}, // Отслеживает состояния загрузки для handlers
      loadingSenders: {}, // Отслеживает состояния загрузки для senders
      loadingCollects: {}, // Отслеживает состояния загрузки для collects
      loadingSessions: {}, // Добавлено для отслеживания загрузок сессий
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isAdmin", "userRole"]),
    username() {
      return localStorage.getItem("username") || "";
    },
  },
  methods: {
    async fetchUserRole() {
      try {
        const response = await apiClient.get("/user-role");
        console.log("Полученная роль:", response.data);
        this.$store.dispatch("updateUserRole", response.data);
      } catch (error) {
        console.error("Ошибка при загрузке роли пользователя:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      }
    },
    // Методы для сворачивания разделов
    toggleSessions() {
      this.isSessionsCollapsed = !this.isSessionsCollapsed;
      localStorage.setItem("isSessionsCollapsed", this.isSessionsCollapsed);
    },
    toggleCollects() {
      this.isCollectsCollapsed = !this.isCollectsCollapsed;
      localStorage.setItem("isCollectsCollapsed", this.isCollectsCollapsed);
    },
    toggleHandlers() {
      this.isHandlersCollapsed = !this.isHandlersCollapsed;
      localStorage.setItem("isHandlersCollapsed", this.isHandlersCollapsed);
    },
    // Методы для модальных окон
    showCreateSessionModal() {
      this.isCreateSessionModalVisible = true;
    },
    showCreateCollectModal() {
      this.isCreateCollectModalVisible = true;
    },
    showCreateHandlerModal() {
      this.isCreateHandlerModalVisible = true;
    },
    openEditHandler(name) {
      this.currentHandlerName = name;
      this.isEditHandlerModalVisible = true;
    },
    closeEditHandlerModal() {
      this.isEditHandlerModalVisible = false;
      this.currentHandlerName = "";
    },
    showCreateSenderModal(handlerId) {
      this.selectedHandlerId = handlerId;
      this.isCreateSenderModalVisible = true;
    },

    // Методы для загрузки данных
    async fetchContainers() {
      try {
        const response = await apiClient.get("/containers/status");
        const containers = response.data;

        console.log(containers); // Для отладки, чтобы увидеть все контейнеры

        // Отфильтровываем сессии, коллекты и хэндлеры
        this.sessions = containers.sessions;
        this.collects = containers.collects;

        // Привязываем сендеров к хэндлерам
        this.handlers = containers.handlers.map((handler) => ({
          ...handler,
          senders: handler.senders || [], // Привязать senders к каждому handler
          send: handler.send, // Каждый раз актуализируем состояние send
        }));
      } catch (error) {
        console.error("Ошибка при загрузке контейнеров:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      }
    },

    // Методы для действий с Collect
    async startCollect(name) {
      this.loadingCollects[name] = true; // Устанавливаем состояние загрузки для Collect
      try {
        await apiClient.post(`/collects/${name}/start`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при старте collect:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingCollects[name] = false;
      }
    },
    async stopCollect(name) {
      this.loadingCollects[name] = true;
      try {
        await apiClient.post(`/collects/${name}/stop`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при остановке collect:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingCollects[name] = false;
      }
    },
    async deleteCollect(name) {
      this.loadingCollects[name] = true;
      try {
        await apiClient.delete(`/collects/${name}`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при удалении collect:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingCollects[name] = false;
      }
    },
    // Методы для действий с Handler
    async startHandler(name) {
      this.loadingHandlers[name] = true;
      try {
        await apiClient.post(`/handlers/${name}/start`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при старте handler:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingHandlers[name] = false;
      }
    },
    async stopHandler(name) {
      this.loadingHandlers[name] = true;
      try {
        await apiClient.post(`/handlers/${name}/stop`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при остановке handler:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingHandlers[name] = false;
      }
    },
    async deleteHandler(name) {
      this.loadingHandlers[name] = true;
      try {
        await apiClient.delete(`/handlers/${name}`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при удалении handler:", error);
        if (error.response && error.response.status === 401) {
          this.$router.push("/login");
        }
      } finally {
        this.loadingHandlers[name] = false;
      }
    },
    async handleToggleSend({ handlerName, send }) {
      // Найти индекс обработчика в массиве handlers
      const handlerIndex = this.handlers.findIndex(
        (handler) => handler.name === handlerName
      );
      if (handlerIndex === -1) {
        console.error(`Handler с именем ${handlerName} не найден.`);
        return;
      }

      // Установить состояние загрузки для конкретного обработчика
      this.loadingHandlers[handlerName] = true;

      try {
        // Выполнить API-запрос на изменение состояния send
        const response = await apiClient.post("/toggle", {
          handler_name: handlerName,
          state: send ? "on" : "off",
        });

        if (response.status === 200) {
          const { send: updatedSend } = response.data;

          // Обновить состояние send в массиве handlers
          this.handlers[handlerIndex] = {
            ...this.handlers[handlerIndex],
            send: updatedSend,
          };

          this.fetchContainers();
        } else {
          throw new Error(`Неожиданный статус ответа: ${response.status}`);
        }
      } catch (error) {
        console.error("Ошибка при обновлении состояния send:", error);

        if (error.response && error.response.status === 429) {
          // Откатить локальное состояние переключателя
          this.handlers[handlerIndex] = {
            ...this.handlers[handlerIndex],
            send: !send,
          };
        } else {
          this.handlers[handlerIndex] = {
            ...this.handlers[handlerIndex],
            send: !send,
          };
        }
      } finally {
        // Сбросить состояние загрузки
        this.loadingHandlers[handlerName] = false;
      }
    },

    // Методы для действий с Sender
    async startSender(name) {
      this.loadingSenders[name] = true;
      try {
        await apiClient.post(`/senders/${name}/start`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при запуске sender:", error);
      } finally {
        this.loadingSenders[name] = false;
      }
    },
    async stopSender(name) {
      this.loadingSenders[name] = true;
      try {
        await apiClient.post(`/senders/${name}/stop`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при остановке sender:", error);
      } finally {
        this.loadingSenders[name] = false;
      }
    },
    async deleteSender(name) {
      this.loadingSenders[name] = true;
      try {
        await apiClient.delete(`/senders/${name}`);
        this.fetchContainers();
      } catch (error) {
        console.error("Ошибка при удалении sender:", error);
      } finally {
        this.loadingSenders[name] = false;
      }
    },
    // Метод для обновления данных при обновлении сессии
    handleRefreshSession(sessionId) {
      this.sessionToRefresh = sessionId;
      this.isRefreshSessionModalVisible = true;
    },
  },
  beforeUnmount() {
    if (this.statusInterval) {
      clearInterval(this.statusInterval);
    }
  },
  async mounted() {
    await this.fetchUserRole();
    this.fetchContainers();
    // Получаем сохраненные состояния при загрузке компонента
    this.isSessionsCollapsed =
      JSON.parse(localStorage.getItem("isSessionsCollapsed")) || false;
    this.isCollectsCollapsed =
      JSON.parse(localStorage.getItem("isCollectsCollapsed")) || false;
    this.isHandlersCollapsed =
      JSON.parse(localStorage.getItem("isHandlersCollapsed")) || false;

    this.statusInterval = setInterval(() => {
      this.fetchContainers();
    }, 10000); // Обновляем статус каждые 10 секунд
  },
};
</script>

<style scoped>
.dashboard {
  padding: 40px;
  background-color: #f0f4f8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 30px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
}

.dashboard-section {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 40px;
  background: #ffffff;
  padding: 25px 30px;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease;
}

.dashboard-section:hover {
  box-shadow: 0 6px 25px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dashboard-section h2 {
  font-size: 1.8rem;
  color: #2980b9;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 8px;
  margin: 0;
}

.collapse-button {
  background: none;
  border: none;
  color: #2980b9;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s ease, transform 0.5s ease; /* Увеличенная продолжительность трансформации */
}

.collapse-button:hover {
  color: #1abc9c;
  transform: rotate(90deg); /* Поворот при наведении */
}

.sessions-container,
.collects-container,
.handlers-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

/* Удалён стиль .container-card, так как он, вероятно, управляется внутри отдельных компонентов */

.create-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
}

.create-button {
  padding: 10px 20px;
  background-color: #2980b9; /* Синий цвет акцента */
  color: #ffffff;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.create-button:hover {
  background-color: #1abc9c; /* Зеленый цвет при наведении */
  transform: translateY(-2px);
}

.create-button .fas.fa-plus {
  margin-right: 8px;
}

.create-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(26, 188, 156, 0.5);
}

/* Обновленные анимации переходов */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 1000px; /* Достаточно большое значение для охвата содержимого */
  opacity: 1;
}

/* Дополнительные стили для плавности иконки */
.collapse-button i {
  transition: transform 0.5s ease; /* Увеличенная продолжительность для большей плавности */
}

.collapse-button[aria-expanded="false"] i {
  transform: rotate(0deg);
}

.collapse-button[aria-expanded="true"] i {
  transform: rotate(180deg); /* Поворот на 180 градусов для инверсии стрелки */
}

@media (max-width: 768px) {
  .dashboard {
    padding: 20px;
  }

  .dashboard h1 {
    font-size: 2rem;
  }

  .dashboard-section {
    padding: 20px;
  }

  .dashboard-section h2 {
    font-size: 1.5rem;
  }

  .create-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .section-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .collapse-button {
    margin-top: 10px;
  }
}
</style>
