<template>
  <div class="handler-card">
    <!-- Отображение анимации загрузки поверх карточки -->
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>

    <!-- Клик в этой области вызывает редактирование, только если не загружается -->
    <div class="clickable-area" @click="handleCardClick">
      <div class="card-header-with-actions">
        <h3>{{ name }}</h3>
        <div class="handler-actions-container">
          <!-- Переключатель Send -->
          <div
            class="send-toggle-inline"
            @click.stop
            v-if="isAdmin && status === 'running'"
          >
            <span class="send-label">
              {{ localSend ? "Send On" : "Send Off" }}
            </span>
            <label class="switch">
              <input
                type="checkbox"
                v-model="localSend"
                @change="toggleSend"
                :disabled="loading"
              />
              <span class="slider round"></span>
            </label>
          </div>
          <div v-if="isAdmin" class="handler-actions">
            <button
              v-if="status === 'exited' || status === 'not found'"
              @click.stop="$emit('start')"
              class="action-button start"
              aria-label="Start"
              :disabled="loading"
            >
              <i class="fas fa-play"></i> Start
            </button>
            <button
              v-if="status === 'running'"
              @click.stop="$emit('stop')"
              class="action-button stop"
              aria-label="Stop"
              :disabled="loading"
            >
              <i class="fas fa-stop"></i> Stop
            </button>
            <button
              v-if="status !== 'not found'"
              @click.stop="$emit('delete')"
              class="action-button delete"
              aria-label="Delete"
              :disabled="loading"
            >
              <i class="fas fa-trash-alt"></i> Delete
            </button>
          </div>
        </div>
      </div>

      <hr class="handler-divider" />

      <div class="card-body">
        <p class="status">
          Status: <span :class="statusClass">{{ status }}</span>
        </p>
        <p>
          Uptime: <span>{{ uptime }}</span>
        </p>
        <p v-if="description" class="description">
          Description: {{ description }}
        </p>
      </div>
    </div>

    <!-- Кнопка сворачивания списка Sender отображается только если есть senders -->
    <div class="section-header" v-show="sendEnabled">
      <h4>Senders</h4>
      <button
        @click="toggleSenders"
        class="collapse-button right"
        :aria-expanded="!isSendersCollapsed"
        aria-controls="senders-content"
        aria-label="Toggle Senders"
      >
        <i
          :class="
            isSendersCollapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
          "
        ></i>
      </button>
    </div>

    <!-- Отображение списка sender-ов появляется только при sendEnabled и если список не свёрнут -->
    <transition name="slide-fade">
      <div
        v-show="sendEnabled && !isSendersCollapsed"
        class="senders-list"
        @click.stop
      >
        <div class="senders-container">
          <SenderCard
            v-for="sender in senderList"
            :key="sender.id"
            :name="sender.name"
            :status="sender.status"
            :uptime="sender.uptime"
            :description="sender.description"
            :isAdmin="isAdmin"
            :loading="loadingsenders[sender.name] || false"
            @start-sender="startSender(sender.name)"
            @stop-sender="stopSender(sender.name)"
            @delete-sender="deleteSender(sender.name)"
          />
        </div>
      </div>
    </transition>

    <!-- Кнопка создания нового sender -->
    <div
      class="create-sender-button-wrapper"
      @click.stop
      v-if="sendEnabled && !isSendersCollapsed"
    >
      <button
        @click="$emit('create-sender', handler_id)"
        class="create-sender-button"
      >
        <i class="fas fa-plus"></i> Создать Sender
      </button>
    </div>
  </div>
</template>

<script>
import SenderCard from "./SenderCard.vue";

export default {
  name: "HandlerCard",
  components: {
    SenderCard,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    uptime: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    send: {
      type: Boolean,
      default: false,
    },
    senders: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingsenders: {
      type: Object,
      required: true,
    },
    handler_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localSend: this.send,
      sendEnabled: this.send,
      isSendersCollapsed: false, // Новое состояние для сворачивания списка Sender
    };
  },
  computed: {
    statusClass() {
      return {
        "status-ok": this.status === "running",
        "status-exited": this.status === "exited",
        "status-error": this.status === "error",
        "status-not-found": this.status === "not found",
      };
    },
    senderList() {
      return this.senders;
    },
  },
  watch: {
    send(newVal) {
      this.localSend = newVal;
      this.sendEnabled = newVal;
    },
  },
  methods: {
    toggleSenders() {
      this.isSendersCollapsed = !this.isSendersCollapsed; // Переключаем состояние сворачивания
    },
    toggleSend() {
      if (this.loading) return;
      const desiredState = this.localSend;
      this.$emit("toggle-send", {
        handlerName: this.name,
        send: desiredState,
      });
    },
    startSender(name) {
      this.$emit("start-sender", name);
    },
    stopSender(name) {
      this.$emit("stop-sender", name);
    },
    deleteSender(name) {
      this.$emit("delete-sender", name);
    },
    handleCardClick() {
      if (!this.loading) {
        this.$emit("open-edit-handler", this.name);
      }
    },
  },
};
</script>

<style scoped>
.handler-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 25px 30px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 25px;
  position: relative;
}

.handler-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
}

.clickable-area {
  cursor: pointer;
  transition: box-shadow 0.3s ease;
  padding: 25px;
}

.handler-card > .clickable-area {
  box-shadow: inset 0 -5px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border-radius: 15px;
}

.handler-divider {
  margin: 20px 0;
  border: 1px solid #ddd;
}

.card-header-with-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.card-header-with-actions h3 {
  font-size: 1.8rem;
  color: #2980b9;
  margin: 0;
  font-weight: 600;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 15px;
  padding: 0 10px;
}

.section-header h4 {
  font-size: 1.8rem;
  color: #2980b9;
  margin: 0;
  font-weight: 600;
}

.handler-actions-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.send-toggle-inline {
  display: flex;
  align-items: center;
  position: relative;
}

.send-label {
  margin: 0 15px 0 10px;
}

.handler-actions {
  display: flex;
  gap: 10px;
}

.action-button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.action-button.start {
  background-color: #28a745;
}

.action-button.start:hover {
  background-color: #218838;
  transform: translateY(-2px);
}

.action-button.stop {
  background-color: #ffc107;
}

.action-button.stop:hover {
  background-color: #e0a800;
  transform: translateY(-2px);
}

.action-button.delete {
  background-color: #dc3545;
}

.action-button.delete:hover {
  background-color: #c82333;
  transform: translateY(-2px);
}

.action-button i {
  margin-right: 8px;
  transition: transform 0.3s ease;
}

.card-body {
  font-size: 1rem;
  word-wrap: break-word;
  color: #2c3e50;
}

.card-body p {
  margin: 5px 0;
}

.description {
  margin-top: 10px;
  font-size: 1rem;
  color: #333;
}

.status {
  font-weight: 500;
}

.status-ok {
  color: #28a745;
}

.status-exited {
  color: #6c757d;
}

.status-error {
  color: #dc3545;
}

.status-not-found {
  color: #6c757d;
}

/* Стиль переключателя Send */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(30px);
}

.slider.round {
  border-radius: 30px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Кнопка сворачивания секции */
.collapse-button {
  background: none;
  border: none;
  color: #2980b9;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  transition: color 0.3s ease, transform 0.3s ease;
  margin-left: auto; /* Выравнивание кнопки вправо */
}

.collapse-button:hover {
  color: #1abc9c;
}

.collapse-button i {
  transition: transform 0.3s ease;
}

.collapse-button[aria-expanded="false"] i {
  transform: rotate(0deg);
}

.collapse-button[aria-expanded="true"] i {
  transform: rotate(180deg);
}

.senders-list h4 {
  font-size: 1.8rem;
  color: #2980b9;
  border-bottom: 2px solid #ecf0f1;
  padding-bottom: 8px;
  margin: 0;
}

/* Разделение обработчика от sender-ов */
.senders-list {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.senders-container {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.create-sender-button-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 25px;
}

.create-sender-button {
  padding: 10px 20px;
  background-color: #2980b9;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.create-sender-button:hover {
  background-color: #1abc9c;
  transform: translateY(-2px);
}

.create-sender-button i {
  margin-right: 8px;
}

/* Анимация сворачивания */
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  overflow: hidden;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  max-height: 0;
  opacity: 0;
}

.slide-fade-enter-to,
.slide-fade-leave-from {
  max-height: 1000px;
  opacity: 1;
}

/* Оверлей загрузки */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  z-index: 10;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
